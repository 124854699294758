import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OGModalType } from "@/Types/CommonComponents/UiComponentsType";
import FullScreenLoader from "../Common/FUllScreenLoader/FullScreenLoader";

const OGModal = ({
  title,
  happyText,
  sadText,
  open,
  handleClose,
  ModalBody,
  handleSubmit,
  maxWidth,
  classNameExtension,
  onlyHappyText,
  disabled,
  loaderShow,
  loadingIndicator,
  // isLoading
}: OGModalType) => {
  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    p: 5,
  };
  return (
    <>
      <Modal
        className={`common-modal-style ${classNameExtension}`}
        open={open as boolean}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="common-modal-box" style={{ maxWidth: maxWidth ? maxWidth : "800px" }}>
          <div className="modal-cross" onClick={handleClose}>
            <CloseIcon />
          </div>
          {title ? <h4 className={`mb-25 ${!handleSubmit && "text-center"}`}>{title}</h4> : null}
          <div>{ModalBody}</div>
          {handleSubmit ? (
            <div className="col-lg-12 btn-wraps interview-btn">
              <Button
                variant="contained"
                className="nu-btn"
                sx={{ minWidth: 140 }}
                onClick={handleSubmit}
                disabled={disabled}
              >
                {loadingIndicator ? "Please Wait..." : happyText}
              </Button>
              <Button
                variant="outlined"
                className="nu-btn"
                sx={{ minWidth: 140, ml: 2 }}
                onClick={handleClose}
                disabled={disabled}
              >
                {sadText}
              </Button>
            </div>
          ) : null}
          {onlyHappyText && (
            <div className="col-lg-12 btn-wraps single-btn">
              <Button variant="contained" className="nu-btn" sx={{ minWidth: 140 }} onClick={handleClose}>
                {onlyHappyText}
              </Button>
            </div>
          )}
        </Box>
      </Modal>
      {loaderShow && <FullScreenLoader />}
    </>
  );
};

export default OGModal;

/**
 * Use pattern for this component
 * 
 * <OGModal
        title={firstLetterCapitalFunc("Logging out? ")}
        open={logout}
        ModalBody={<LogoutComponent />}
        handleClose={handleClose}
        handleSubmit={handleLogout}
        sadText="No!"
        happyText="Yes!"
      />
 */

import { getNotSelectedApplicants } from "@/Services/apis/applicants.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  notSelectedCandidatesList: {},
};
export const getNotSelectedApplicantsList = createAsyncThunk(
  "getNotSelectedApplicantsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getAppliedApplicantsData: JobListType = await getNotSelectedApplicants(value);
      if (getAppliedApplicantsData?.code !== 200) return rejectWithValue(getAppliedApplicantsData);
      return getAppliedApplicantsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const notSelectedApplicantsSlice = createSlice({
  name: "notSelectedApplicantsSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setNotSelectedApplicantsLists: (state, action) => {
      state.notSelectedCandidatesList = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getNotSelectedApplicantsList.pending, (_state) => {})
      .addCase(getNotSelectedApplicantsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.notSelectedCandidatesList = {
            code: payload?.code,
            data: {
              list: [...state.notSelectedCandidatesList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.notSelectedCandidatesList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getNotSelectedApplicantsList.rejected, (_state) => {});
  },
});

export const {
  //eslint-disable-line
  setNotSelectedApplicantsLists,
} = notSelectedApplicantsSlice.actions;

export default notSelectedApplicantsSlice.reducer;

import React, { useEffect, useState } from "react";
import Image from "next/image";
import dashLogo from "@/../../public/assets/dashboardLogo.svg";
import navIcon1 from "@/../../public/assets/icon1.svg";
import navIcon2 from "@/../../public/assets/icon2.svg";
import navIcon3 from "@/../../public/assets/icon3.svg";
import navIcon4 from "@/../../public/assets/icon4.svg";
import navIcon5 from "@/../../public/assets/icon5.svg";
import dashboardIcon from "@/../../public/assets/icon6.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OGModal from "@/Components/UiComponents/OGModal";
import { usePathname, useRouter } from "next/navigation";
import { NavbarType } from "@/Types/CommonComponents/UiComponentsType";
import { clearLocalStorageExceptSomeKey, firstLetterCapitalFunc } from "@/Utils/CommonFuncs";
import { getUserData, handleSignOut } from "@/Utils/cognito-auth-functions";
import { GetCognitoUserDataType } from "@/Types/CognitoTypes";
import abilities from "@/Utils/CANAbility/abilities.json";
import Authorizer from "../Authorizer/Authorizer";
import Cookie from "universal-cookie";
import useClickable from "@/Utils/UseClickable";

// import { firstLetterCapitalFunc } from "@/Utils/CommonFuncs";
const CustomSideNav = ({ openHamburger, setExpanded, expanded, setOpenHamburger }: NavbarType) => {
  // Hooks Declaration
  const router = useRouter();
  const pathname = usePathname();
  const clickable = useClickable();

  // States declaration
  // const [expanded, setExpanded] = React.useState<string | false>("content1");
  const [logout, setLogout] = useState<boolean>(false);
  const [loggedOutLoader, setLoggedOutLoader] = useState<boolean>(false);
  const [cognitoUserData, setCognitoUserData] = useState<GetCognitoUserDataType>();
  // Functions Declaration
  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClose = () => {
    setLogout(false);
  };
  const handleOpen = () => {
    setLogout(true);
  };
  const handleLogout = async () => {
    setLoggedOutLoader(true);
    const cookie = new Cookie();
    const cognitoUser = await getUserData();
    clearLocalStorageExceptSomeKey(["deviceModel", "appVerison", "deviceKind", "deviceId"]);
    sessionStorage.clear();
    cookie.remove("currentDashboardRole");
    if (cognitoUser) {
      handleSignOut();
    }
  };
  // Logout Modal Body section
  const LogoutComponent = () => {
    return <p>You’ll need to sign back in to return. Stay OG!</p>;
  };

  const handleAttributeMoveIn = () => {
    const attribute = document.documentElement.getAttribute("sidenav-status");
    if (attribute === "close") {
      document.documentElement.setAttribute("sidenav-status", "open");
    }
  };

  const getCoguser: any = async () => {
    // eslint-disable-line
    const userData = (await getUserData()) as GetCognitoUserDataType;
    setCognitoUserData(userData as GetCognitoUserDataType);
  };
  const handleRouteChange = (route: string) => {
    router.push(route);
    setOpenHamburger(false);
  };
  useEffect(() => {
    getCoguser();
  }, []);
  return (
    <div className={`dashboard-sidenav ${openHamburger ? "dashboard-sidenav-active" : ""}`}>
      <div className="dash-logo-wrap pointer" onClick={() => handleRouteChange("/feeds")}>
        <Image alt="logo" src={dashLogo} className="dash-logo" priority />
      </div>

      <div className="dashboard-menu" onMouseEnter={handleAttributeMoveIn}>
        <Authorizer resource={abilities?.FeedSection}>
          <Accordion
            onMouseEnter={() => router.prefetch("/feeds")} // Prefetch route on hover
            onTouchStart={() => router.prefetch("/feeds")}
            className="custom-sidenav"
            expanded={expanded === "content1" || pathname?.includes("/feeds")}
            onChange={handleChange("content1")}
            onClick={() => handleRouteChange("/feeds")}
          >
            <AccordionSummary className="custom-sidenav-list">
              <div className="sidenav-li">
                <Image alt="logo" src={navIcon1} className="dashboard-menuIcon" />
                <span>Feed</span>
              </div>
            </AccordionSummary>
          </Accordion>
        </Authorizer>
        <Accordion
          className="custom-sidenav"
          expanded={expanded === "content6" || pathname?.includes("/profile/view")}
          onChange={handleChange("content6")}
          onClick={() => handleRouteChange("/profile/view")}
          onMouseEnter={() => router.prefetch("/profile/view")}
          onTouchStart={() => router.prefetch("/profile/view")}
        >
          <AccordionSummary className="custom-sidenav-list">
            <div className="sidenav-li">
              <Image alt="logo" src={dashboardIcon} width={20} className="dashboard-menuIcon" />
              <span>Profile</span>
            </div>
          </AccordionSummary>
        </Accordion>

        {/* To do start */}
        <Authorizer resource={abilities?.JobCreate}>
          <Accordion
            className={`custom-sidenav ${pathname?.includes("/jobs/") && "sideNavSelected"}`}
            expanded={expanded === "content2"}
            onChange={handleChange("content2")}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon className="nav-arrow" />} className="custom-sidenav-list">
              <div className="sidenav-li">
                <Image alt="logo" src={navIcon2} className="dashboard-menuIcon" /> <span>Jobs</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className="custom-sub-menu">
              <ul>
                <li
                  className={`${pathname?.includes("/jobs/post") && "selectedList"}`}
                  onClick={() => {
                    clickable(() => {
                      handleRouteChange("/jobs/post");
                    });
                  }}
                  onMouseEnter={() => router.prefetch("/jobs/post")}
                  onTouchStart={() => router.prefetch("/jobs/post")}
                >
                  Post a Job
                </li>

                <Authorizer resource={abilities?.MyJobs}>
                  <li
                    className={`${pathname?.includes("/jobs/my-jobs") && "selectedList"}`}
                    onClick={() => handleRouteChange("/jobs/my-jobs?value=1")}
                  >
                    Jobs
                  </li>
                </Authorizer>
                <li
                  className={`${pathname?.includes("/jobs/list") && "selectedList"}`}
                  onClick={() => handleRouteChange("/jobs/list?value=1")}
                  onMouseEnter={() => router.prefetch("/jobs/list?value=1")}
                  onTouchStart={() => router.prefetch("/jobs/list?value=1")}
                >
                  {firstLetterCapitalFunc("Open & Closed roles")}
                </li>
              </ul>
            </AccordionDetails>
          </Accordion>
        </Authorizer>

        {
          <Authorizer resource={abilities?.DefaultRoleInterviewSection}>
            {/* <Accordion
          className={`custom-sidenav ${pathname?.includes('/interviews') && 'sideNavSelected'}`}
          expanded={expanded === "content3"}
          onChange={handleChange("content3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="nav-arrow" />}
            className="custom-sidenav-list"
          >
            <div className="sidenav-li">
              <Image alt="logo" src={navIcon3} className="dashboard-menuIcon" />{" "}
              <span>Interviews</span>
            </div>
          </AccordionSummary>
          <AccordionDetails className="custom-sub-menu">
            <ul>
              <li>Upcoming Interviews</li>
              <li>Completed Interviews</li>
            </ul>
          </AccordionDetails>
        </Accordion> */}
            <Accordion
              className={`custom-sidenav ${pathname?.includes("/interviews/personal?value=1") && "sideNavSelected"}`}
              expanded={expanded === "content3" || pathname?.includes("/interviews/personal?value=1")}
              onChange={handleChange("content3")}
              onClick={() => handleRouteChange("/interviews/personal?value=1")}
              onMouseEnter={() => router.prefetch("/interviews/personal?value=1")}
              onTouchStart={() => router.prefetch("/interviews/personal?value=1")}
            >
              <AccordionSummary className="custom-sidenav-list">
                <div className="sidenav-li">
                  <Image alt="logo" src={navIcon3} className="dashboard-menuIcon" /> <span>Interviews</span>
                </div>
              </AccordionSummary>
            </Accordion>
          </Authorizer>
        }
        {
          <Authorizer resource={abilities?.InterviewerRoleInterviewSection}>
            {/* <Accordion
          className={`custom-sidenav ${pathname?.includes('/interviews/interviewer') && 'sideNavSelected'}`}
          expanded={expanded === "content3"}
          onChange={handleChange("content3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="nav-arrow" />}
            className="custom-sidenav-list"
          >
            <div className="sidenav-li">
              <Image alt="logo" src={navIcon3} className="dashboard-menuIcon" />{" "}
              <span>Interviews</span>
            </div>
          </AccordionSummary>
          <AccordionDetails className="custom-sub-menu">
            <ul>
              <li>Upcoming Interviews</li>
              <li>Completed Interviews</li>
            </ul>
          </AccordionDetails>
        </Accordion> */}
            <Accordion
              className={`custom-sidenav ${pathname?.includes("/interviews/interviewer?value=1") && "sideNavSelected"}`}
              expanded={expanded === "content3" || pathname?.includes("/interviews/interviewer?value=1")}
              onChange={handleChange("content3")}
              onClick={() => handleRouteChange("/interviews/interviewer?value=1")}
              onMouseEnter={() => router.prefetch("/interviews/interviewer?value=1")}
              onTouchStart={() => router.prefetch("/interviews/interviewer?value=1")}
            >
              <AccordionSummary className="custom-sidenav-list">
                <div className="sidenav-li">
                  <Image alt="logo" src={navIcon3} className="dashboard-menuIcon" /> <span>Interviews</span>
                </div>
              </AccordionSummary>
            </Accordion>
          </Authorizer>
        }

        <Accordion
          className={`custom-sidenav ${pathname?.includes("/chat") && "sideNavSelected"}`}
          expanded={expanded === "content4" || pathname?.includes("/chat")}
          onChange={handleChange("content4")}
          onClick={() => handleRouteChange("/chat")}
          onMouseEnter={() => router.prefetch("/chat")}
          onTouchStart={() => router.prefetch("/chat")}
        >
          <AccordionSummary className="custom-sidenav-list">
            <div className="sidenav-li">
              <Image alt="logo" src={navIcon4} className="dashboard-menuIcon" /> <span>Chat</span>
            </div>
          </AccordionSummary>
        </Accordion>
        {/* To do end */}
        <Accordion
          className={`custom-sidenav ${pathname?.includes("/settings/") && "sideNavSelected"}`}
          expanded={expanded === "content5"}
          onChange={handleChange("content5")}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon className="nav-arrow" />} className="custom-sidenav-list">
            <div className="sidenav-li">
              <Image alt="logo" src={navIcon5} className="dashboard-menuIcon" /> <span>Settings</span>
            </div>
          </AccordionSummary>
          <AccordionDetails className="custom-sub-menu">
            <ul>
              {/* To do start */}
              {/* <li>Preferences </li>
              <li>Privacy Settings</li> */}
              {/* To do end */}
              <Authorizer resource={abilities?.AdminControlUser}>
                <li
                  className={`${pathname?.includes("/settings/administrative-controls") && "selectedList"}`}
                  onClick={() => handleRouteChange("/settings/administrative-controls")}
                  onMouseEnter={() => router.prefetch("/settings/administrative-controls")}
                  onTouchStart={() => router.prefetch("/settings/administrative-controls")}
                >
                  Administrative Controls
                </li>
              </Authorizer>
              {/* <Authorizer resource={abilities?.CompanyInvitations}>
                <li
                  className={`${pathname?.includes("/settings/company-invitations") && "selectedList"}`}
                  onClick={() => handleRouteChange("/settings/company-invitations")}
                  onMouseEnter={() => router.prefetch("/settings/company-invitations")}
                >
                  Company Invitations
                </li>
              </Authorizer> */}
              {/* <Authorizer resource={abilities?.AccountSettings}> */}
              <li
                className={`${pathname?.includes("/settings/account-settings") && "selectedList"}`}
                onClick={() => handleRouteChange("/settings/account-settings")}
              >
                Account Settings
              </li>
              <Authorizer resource={abilities?.PaymentRecruiter}>
                <li
                  className={`${pathname?.includes("/settings/payments") && "selectedList"}`}
                  onClick={() => handleRouteChange("/settings/payments")}
                  onMouseEnter={() => router.prefetch("/settings/payments")}
                  onTouchStart={() => router.prefetch("/settings/payments")}
                >
                  Payments
                </li>
              </Authorizer>
              <Authorizer resource={abilities?.PaymentInterviewer}>
                <li
                  className={`${pathname?.includes("/settings/payout") && "selectedList"}`}
                  onClick={() => handleRouteChange("/settings/payouts")}
                  onMouseEnter={() => router.prefetch("/settings/payouts")}
                  onTouchStart={() => router.prefetch("/settings/payouts")}
                >
                  Payouts
                </li>
              </Authorizer>

              {/* </Authorizer> */}
              {/* To do start */}
              {/* <li>Payments</li>
              <li>Help and Support</li>
              <li>Legal and Security</li> */}
              {/* To do end */}

              {/* <Authorizer resource={abilities?.ChangePassword}>
                {!cognitoUserData?.identities?.length && (
                  <li
                    className={`${pathname?.includes("/settings/change-password") && "selectedList"}`}
                    onClick={() => handleRouteChange("/settings/change-password")}
                    onMouseEnter={() => router.prefetch("/settings/change-password")}
                    onTouchStart={() => router.prefetch("/settings/change-password")}
                  >
                    Change Password
                  </li>
                )}
              </Authorizer> */}
              <li className="logout" onClick={handleOpen}>
                Logout
              </li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </div>
      {/* Logout Modal */}
      <OGModal
        title={firstLetterCapitalFunc("Logging out? ")}
        open={logout}
        ModalBody={<LogoutComponent />}
        handleClose={handleClose}
        handleSubmit={handleLogout}
        sadText="No!"
        happyText="Yes!"
        disabled={loggedOutLoader}
        loadingIndicator={loggedOutLoader}
      />
    </div>
  );
};

export default CustomSideNav;

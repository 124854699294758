import { interviewerSignupApi, verifyReferralCodeConfirmationApi } from "@/Services/apis/becomeInterviewer.api";
import { getS3SignUrl } from "@/Services/apis/common.api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUser } from "../userProfile.slice";
import { toast } from "react-toastify";
import { getLocalstorageData } from "@/Utils/CommonFuncs";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  addressProofFront: {},
  addressProofBack: {},
  addVerification: {},
  criminalBgVerification: {},
};
/* eslint-enable @typescript-eslint/no-explicit-any */
//All file uploads
/* eslint-disable @typescript-eslint/no-explicit-any */
export const fileUploadInterviewer = createAsyncThunk(
  "fileUploadInterviewer",
  async (values: any, { rejectWithValue }) => {
    const file = values.blobFile;
    values.role = getLocalstorageData("currentDashboardRole");
    try {
      const data: any = await getS3SignUrl(values);

      if (data?.code !== 200) return rejectWithValue(data);
      if (data?.code === 200) {
        if (values?.type === "addressProofFront") {
          values?.dispatch(
            setAddressProofFront({
              file,
              type: values?.dataFiles?.ContentType,
              name: values?.dataFiles?.fileName,
              link: data?.uploadImageUrl,
            })
          );
          values?.becomeInterviwerFormik?.setFieldValue("addressProofFront", "done");
        }
        if (values?.type === "addressProofBack") {
          values?.dispatch(
            setAddressProofBack({
              file,
              type: values?.dataFiles?.ContentType,
              name: values?.dataFiles?.fileName,
              link: data?.uploadImageUrl,
            })
          );
          values?.becomeInterviwerFormik?.setFieldValue("addressProofBack", "done");
        }
      }
      return { data };
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
/* eslint-enable @typescript-eslint/no-explicit-any */
//Verify referral code
/* eslint-disable @typescript-eslint/no-explicit-any */

export const verifyReferralCode = createAsyncThunk("verifyReferralCode", async (values: any, { rejectWithValue }) => {
  try {
    const verifyReferralCodeConfirmation: any = await verifyReferralCodeConfirmationApi(values);
    if (verifyReferralCodeConfirmation?.code !== 200) return values?.setIsLoading(false);
    rejectWithValue(verifyReferralCodeConfirmation);
    if (verifyReferralCodeConfirmation?.code === 200) {
      // values?.setReferralModalOpen(false);
      toast.success(verifyReferralCodeConfirmation?.message);
      values?.setIsLoading(false);
    }
    return verifyReferralCodeConfirmation;
  } catch (error: any) {
    // values?.router && values?.router?.replace("/feeds");
    values?.setIsLoading(false);
    return rejectWithValue(error.response.data);
  }
});
// Interviewer Sign up
export const interviewerSignup = createAsyncThunk("interviewerSignup", async (values: any, { rejectWithValue }) => {
  try {
    const interviewerSignupdetails: any = await interviewerSignupApi(values);
    if (interviewerSignupdetails?.code !== 201) return rejectWithValue(interviewerSignupdetails);
    if (interviewerSignupdetails?.code === 201) {
      values.dispatch(getUser({ resource: "get-user", profileRole: values.role }));
      values?.router && values?.router?.replace("/feeds");
      toast.success(interviewerSignupdetails?.message);
    }
    return interviewerSignupdetails;
  } catch (error: any) {
    // values?.router && values?.router?.replace("/feeds");

    return rejectWithValue(error.response.data);
  }
});

export const becomeInterviewerSlice = createSlice({
  name: "becomeInterviewerSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAddressProofFront: (state, action) => {
      state.addressProofFront = action.payload;
    },
    setAddressProofBack: (state, action) => {
      state.addressProofBack = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder;
  },
});

export const { setAddressProofFront, setAddressProofBack } = becomeInterviewerSlice.actions;

export default becomeInterviewerSlice.reducer;

import { setChatAuthenticatedData } from "@/Redux/chat/chatSection/chatAuth.slice";
import {
  removeArchiveChatRoom,
  removeInactiveChatRoom,
  removePersonalChatRoom,
  removeProfessionalChatRoom,
  removeSearchChatRoom,
  setActiveChatRoom,
  setArchiveChatRoomsList,
  setClearInactiveChatRoom,
  setDeleteChatRoom,
  setDeleteInactiveChatModel,
  setInactiveChatRoomsList,
  setLoaderForRoomList,
  setPersonalChatRoomsList,
  setProfessionalChatRoomsList,
  setRoomDetails,
  setSearchChatRoomsList,
  setUpdatedRoomListen,
  updateArchivedChatRoom,
  updatePersonalChatRoom,
  updateProfessionalChatRoom,
} from "@/Redux/chat/chatSection/chatRoom.slice";
import {
  setClearChatMessages,
  setLoaderForMessagesList,
  setMessages,
  setNewMessages,
  setUpdatedMessage,
} from "@/Redux/chat/chatSection/messages.slice";
import { setInterviewDetails } from "@/Redux/interviews/interviewer.slice";
import { RootState, store } from "@/Redux/store";
import { WebSocketService } from "@/Services/sockets/WebSocketService";
import { getChatRooms, getChatRoomsMessages, updateChatRoomEmit } from "@/Utils/constants/EmitFunctions/emitFunctions";
import { EVENT_LISTEN_ACTIONS } from "@/Utils/constants/SocketsEvents/socketEvents";
import { Action } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

// interface ListneningEventsProps {
//   webSocket: WebSocket;
// }

const ListneningEvents = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>();
  // const { roomDetails, activeChatRoom,store && store?.getState()?.chatRoomReducer?.professionalChatRoomsList, store && store?.getState()?.chatRoomReducer?.searchChatRoomsList } = store.getState().chatRoomReducer;
  // const { chatAuthenticatedData } = useSelector((state: RootState) => state.chatAuthReducer);
  const { selectedDashboardData } = useSelector((state: RootState) => state.userProfile);

  useEffect(() => {
    registerWSListener();
  }, []);

  const registerWSListener = () => {
    WebSocketService.addWSEventListener({
      type: "onmessage",
      callBack: (event: MessageEvent<string>) => {
        const data = event?.data ? JSON.parse(event.data) : {};

        // Handle success responses based on topic
        if (data?.code === 200) {
          switch (data.topic) {
            case EVENT_LISTEN_ACTIONS.AUTHENTICATED_USER:
              handleAuthenticatedUser(data);
              break;
            case EVENT_LISTEN_ACTIONS.FETCHED_CHATROOMS_BY_USER:
              handleFetchedChatroomsByUser(data);
              break;
            case EVENT_LISTEN_ACTIONS.FILTERED_CHATROOMS_BY_USER:
              handleSearchChatroomsByUser(data);
              break;
            case EVENT_LISTEN_ACTIONS.FETCHED_MESSAGES_BY_CHATROOM:
              handlegetMessageByRoomId(data);
              break;
            case EVENT_LISTEN_ACTIONS.MESSAGE_SENT:
              handleGetSendMessage(data);
              break;
            case EVENT_LISTEN_ACTIONS.UPDATE_CHATROOMS_LISTEN:
              handleUpdateChatRooms(data);
              break;
            case EVENT_LISTEN_ACTIONS.ARCHIVE_ROOM_LISTEN:
              handleArchiveRoom(data);
              break;
            case EVENT_LISTEN_ACTIONS.UNARCHIVE_ROOM_LISTEN:
              handleUnArchiveRoom(data);
              break;
            case EVENT_LISTEN_ACTIONS.CREATED_SINGLE_CHATROOM:
              handleCreatedRoom(data);
              break;
            case EVENT_LISTEN_ACTIONS.NEW_CREATED_SINGLE_CHATROOM:
              handleNewCreateRoom();
              break;
            case EVENT_LISTEN_ACTIONS.DELETED_CHAT_INACTIVE:
              handleDeleteChat(data);
              break;
            case EVENT_LISTEN_ACTIONS.DELETED_SINGLE_ROOM_CONVERSATION:
              handleDeleteChatPersonal(data);
              break;
            case EVENT_LISTEN_ACTIONS.MUTED_ROOM_NOTIFICATION:
              handleMuteChatRoomNotification(data);
              break;
            case EVENT_LISTEN_ACTIONS.UNMUTED_ROOM_NOTIFICATION:
              handleUnMuteChatRoomNotification(data);
              break;

            case EVENT_LISTEN_ACTIONS.SEENED_MESSAGE:
              handleSeenMessages(data);
              break;
            case EVENT_LISTEN_ACTIONS.SCHEDULED_INTERVIEW:
              handleGetCustomActionMessage(data);
              break;
            case EVENT_LISTEN_ACTIONS.CONFIRMED_INTERVIEW:
              handleGetCustomActionMessage(data);
              break;
            case EVENT_LISTEN_ACTIONS.CANCELLED_INTERVIEW:
              handleGetCustomActionMessage(data);
              break;
            case EVENT_LISTEN_ACTIONS.RESCHEDULED_INTERVIEW:
              handleGetCustomActionMessage(data);
              break;
            case EVENT_LISTEN_ACTIONS.INTERVIEWER_WALKOUTED:
              handleInterviewerWalkout(data);
              break;
            case EVENT_LISTEN_ACTIONS.CANDIDATE_WALKOUTED:
              handleCandidateWalkout(data);
              break;
            case EVENT_LISTEN_ACTIONS.CANDIDATE_WITHDRAWN:
              handleCandidateWithdrawn(data);
              break;
            case EVENT_LISTEN_ACTIONS.CLOSED_JOB:
              handleJobClosed(data);
              break;
            case EVENT_LISTEN_ACTIONS.INTERVIEW_RECORDED:
              handleVideoUploadViaSession(data);
              break;
            case EVENT_LISTEN_ACTIONS.PERSONAL_RESTART_CHAT:
              handlePersonalRestartChat(data);
              break;
            case EVENT_LISTEN_ACTIONS.DECISION_TAKEN_BY_RECRUITER:
              handleRecruiterDecisionTaken(data);
              break;
            case EVENT_LISTEN_ACTIONS.INTERVIEW_UPDATED:
              handleUpdateInterviewDetails(data);
              break;

            default:
              console.warn("Unhandled topic:", data.topic);
              break;
          }
        } else {
          dispatch(setLoaderForRoomList(false));
          // toast.error(data?.message);

          console.error("Error received:", data?.authenticateUser?.error || "Error in response");
        }
      },
    });
  };

  // Define specific functions for each topic action
  //eslint-disable-next-line

  // Chat Authentication
  const handleAuthenticatedUser = (data: any) => {
    dispatch(setChatAuthenticatedData(data?.data));

    // Add specific handling code here
  };

  // Get Chatrooms With Respective Tabs
  const handleFetchedChatroomsByUser = (data: any) => {
    //eslint-disable-line

    if (data?.query?.roomStatus === "Active" && data?.query?.roomType === "Single") {
      dispatch(setPersonalChatRoomsList(data?.data));
    }
    if (data?.query?.roomStatus === "Active" && data?.query?.roomType === "Group") {
      dispatch(setProfessionalChatRoomsList(data?.data));
    }
    if (data?.query?.roomStatus === "Inactive") {
      dispatch(setInactiveChatRoomsList(data?.data));
    }
    if (data?.query?.roomStatus === "Archive") {
      dispatch(setArchiveChatRoomsList(data?.data));
    }
    dispatch(setLoaderForRoomList(false));
    // Add specific handling code here
  };

  //Get Chatrooms when User Search

  const handleSearchChatroomsByUser = (data: any) => {
    //eslint-disable-line

    dispatch(setSearchChatRoomsList(data?.data));
    dispatch(setLoaderForRoomList(false));
    // Add specific handling code here
  };

  //Get all messages when user click on any room card
  const handlegetMessageByRoomId = (data: any) => {
    //eslint-disable-line
    dispatch(setMessages(data?.data));
    dispatch(setLoaderForMessagesList(false));
    // Add specific handling code here
  };
  //When user send message
  const handleGetSendMessage = (data: any) => {
    //eslint-disable-line

    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data.message.roomId) {
      dispatch(setNewMessages(data?.data));
    }

    // Add specific handling code here
  };
  //For get updated room
  const handleUpdateChatRooms = (data: any) => {
    //eslint-disable-line

    // alert("kdjsjkd")
    if (data?.data?.roomStatus === "Active" && data?.data?.roomType === "Single") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      if (store && store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length) {
        dispatch(setSearchChatRoomsList(data?.data));
      } else {
        dispatch(setPersonalChatRoomsList(data?.data));
      }
    }
    if (data?.data?.roomStatus === "Active" && data?.data?.roomType === "Group") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      if (store && store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length) {
        dispatch(setSearchChatRoomsList(data?.data));
      } else {
        dispatch(setProfessionalChatRoomsList(data?.data));
      }
    }
    if (data?.data?.roomStatus === "Inactive") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      if (store && store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length) {
        const hasInactiveRoom =
          store &&
          store
            ?.getState()
            ?.chatRoomReducer?.searchChatRoomsList?.list.find((item: any) => item.roomId === data?.data?.roomId);

        if (hasInactiveRoom) {
          dispatch(removeSearchChatRoom(hasInactiveRoom));
        }
      } else {
        const hasInactiveRoom =
          store &&
          store
            ?.getState()
            ?.chatRoomReducer?.professionalChatRoomsList?.list.find((item: any) => item.roomId === data?.data?.roomId);

        if (hasInactiveRoom) {
          dispatch(removeProfessionalChatRoom(hasInactiveRoom));
        }
      }

      dispatch(setInactiveChatRoomsList(data?.data));
    }
    if (data?.data?.roomStatus === "Archive") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      dispatch(setArchiveChatRoomsList(data?.data));
    }
  };

  //When user clicks on Archive
  const handleArchiveRoom = (data: any) => {
    //eslint-disable-line
    // const dataToSend = {
    //   message: {
    //     chatRoomId: roomDetails?.roomId,
    //   },
    // };

    if (data?.data?.roomStatus === "Archive" && data?.data?.roomType === "Single") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
        dispatch(setArchiveChatRoomsList(data?.data));
      }
      dispatch(updatePersonalChatRoom(data?.data));
    }
    if (data?.data?.roomStatus === "Archive" && data?.data?.roomType === "Group") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
        dispatch(setArchiveChatRoomsList(data?.data));
      }
      dispatch(updateProfessionalChatRoom(data?.data));
    }
    // updateChatRoomEmit(dataToSend);

    // Add specific handling code here
  };
  //When user clicks on UnArchive
  const handleUnArchiveRoom = (data: any) => {
    //eslint-disable-line
    // const dataToSend = {
    //   message: {
    //     chatRoomId: roomDetails?.roomId,
    //   },
    // };
    // updateChatRoomEmit(dataToSend);

    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
      dispatch(setRoomDetails(data?.data));
      if (data?.data?.roomType === "Single") {
        dispatch(setPersonalChatRoomsList(data?.data));
      }
      if (data?.data?.roomType === "Group") {
        dispatch(setProfessionalChatRoomsList(data?.data));
      }
    }
    if (data?.data?.roomStatus === "Active") {
      dispatch(updateArchivedChatRoom(data?.data));
    }

    // Add specific handling code here
  };
  //When user creats One to One room first time in his own
  const handleCreatedRoom = (data: any) => {
    dispatch(setActiveChatRoom(data?.data?.roomId));
    dispatch(setRoomDetails(data?.data));
    const dataToSend: any = {
      message: {
        // currentPage: page,
        limit: 10,
        roomType: "Single",
        roomStatus: "Active",
        userId: store && store?.getState()?.chatAuthReducer?.chatAuthenticatedData?._id,
        userRoleProfileId: selectedDashboardData?._id,
      },
    };

    getChatRooms(dataToSend);
  };

  //When one user creats One to One room first time then this will be called for respective other user

  const handleNewCreateRoom = () => {
    dispatch(setLoaderForRoomList(false));
    const dataToSend: any = {
      message: {
        // currentPage: page,
        limit: 10,
        roomType: "Single",
        roomStatus: "Active",
        userId: store && store?.getState()?.chatAuthReducer?.chatAuthenticatedData?._id,
        userRoleProfileId: selectedDashboardData?._id,
      },
    };

    getChatRooms(dataToSend);
  };

  //When user wants to delete the chat from Inactive list/s
  const handleDeleteChat = (data: any) => {
    dispatch(removeInactiveChatRoom(data?.data));
    dispatch(setDeleteChatRoom());
    dispatch(setLoaderForRoomList(false));
    dispatch(setDeleteInactiveChatModel(false));
    dispatch(setClearChatMessages());
    const dataToSend: any = {
      message: {
        // currentPage: page,
        limit: 10,
        // roomType: "",
        roomStatus: "Inactive",
        userId: store && store?.getState()?.chatAuthReducer?.chatAuthenticatedData?._id,
        userRoleProfileId: selectedDashboardData?._id,
      },
    };
    dispatch(setClearInactiveChatRoom());
    getChatRooms(dataToSend);
  };
  //When user wants to delete the conversations from Personal list/s

  const handleDeleteChatPersonal = (data: any) => {
    if (data?.data?.roomStatus === "Archive") {
      dispatch(removeArchiveChatRoom(data?.data));
    } else {
      dispatch(removePersonalChatRoom(data?.data));
    }

    dispatch(setDeleteChatRoom());
    dispatch(setClearChatMessages());
  };
  //When user wants to Mute the notifications of particular chatroom
  const handleMuteChatRoomNotification = (data: any) => {
    if (data?.data?.roomStatus === "Active" && data?.data?.roomType === "Single") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      if (store && store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length) {
        dispatch(setSearchChatRoomsList(data?.data));
      } else {
        dispatch(setPersonalChatRoomsList(data?.data));
      }
    }
    if (data?.data?.roomStatus === "Active" && data?.data?.roomType === "Group") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      if (store && store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length) {
        dispatch(setSearchChatRoomsList(data?.data));
      } else {
        dispatch(setProfessionalChatRoomsList(data?.data));
      }
    }

    if (data?.data?.roomStatus === "Archive") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      dispatch(setArchiveChatRoomsList(data?.data));
    }
  };
  //When user wants to Unmute the notifications of particular chatroom
  const handleUnMuteChatRoomNotification = (data: any) => {
    if (data?.data?.roomStatus === "Active" && data?.data?.roomType === "Single") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      if (store && store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length) {
        dispatch(setSearchChatRoomsList(data?.data));
      } else {
        dispatch(setPersonalChatRoomsList(data?.data));
      }
    }
    if (data?.data?.roomStatus === "Active" && data?.data?.roomType === "Group") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      if (store && store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length) {
        dispatch(setSearchChatRoomsList(data?.data));
      } else {
        dispatch(setProfessionalChatRoomsList(data?.data));
      }
    }

    if (data?.data?.roomStatus === "Archive") {
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomId) {
        dispatch(setRoomDetails(data?.data));
      }
      dispatch(setArchiveChatRoomsList(data?.data));
    }
  };
  //When user wants to read the Unread messages if they have
  const handleSeenMessages = (data: any) => {
    // if(activeChatRoom===data?.data?.roomDetails?.roomId){
    dispatch(setUpdatedMessage(data?.data?.message));
    dispatch(setUpdatedRoomListen(data?.data?.roomDetails));
    // }
  };
  const handleGetCustomActionMessage = (data: any) => {
    //eslint-disable-line
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data.message.roomId) {
      const dataToSend: any = {
        message: {
          limit: 10,
          chatRoomId: store && store?.getState()?.chatRoomReducer?.activeChatRoom,
        },
      };
      if (
        store &&
        store?.getState()?.chatRoomReducer?.store &&
        store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt
      ) {
        dataToSend.message.joinAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt;
      }
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt) {
        dataToSend.message.removeAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt;
      }
      dispatch(setClearChatMessages());
      getChatRoomsMessages(dataToSend);

      // else{
      //   dispatch(setNewMessages(data?.data));

      // }
    }

    // Add specific handling code here
  };
  const handleInterviewerWalkout = (data: any) => {
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.messageData?.message?.roomId) {
      const dataToSend: any = {
        message: {
          limit: 10,
          chatRoomId: store && store?.getState()?.chatRoomReducer?.activeChatRoom,
        },
      };
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt) {
        dataToSend.message.joinAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt;
      }
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt) {
        dataToSend.message.removeAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt;
      }

      dispatch(setClearChatMessages());
      getChatRoomsMessages(dataToSend);

      // else{
      //   dispatch(setNewMessages(data?.data));

      // }
    }
    if (data?.data?.roomData?.roomStatus === "Inactive") {
      const hasInactiveRoom =
        store &&
        store?.getState()?.chatRoomReducer?.professionalChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.professionalChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomData?.chatRoomId) {
        dispatch(
          setRoomDetails({
            ...hasInactiveRoom,
            roomStatus: data?.data?.roomData?.roomStatus,
            members: data?.data?.roomData?.members,
          })
        );
      }

      // if (hasInactiveRoom) {
      //   dispatch(removeProfessionalChatRoom(hasInactiveRoom));
      // }
      // const hasInactiveRoomArchive =
      //   store &&
      //   store?.getState()?.chatRoomReducer?.archiveChatRoomsList?.list?.length &&
      //   store &&
      //   store
      //     ?.getState()
      //     ?.chatRoomReducer?.archiveChatRoomsList?.list?.find(
      //       (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
      //     );
      //     if (hasInactiveRoomArchive) {
      //       dispatch(removeArchiveChatRoom(hasInactiveRoomArchive));
      //     }
      dispatch(setInactiveChatRoomsList(data?.data));
    }
  };

  const handleCandidateWalkout = (data: any) => {
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.messageData?.message?.roomId) {
      const dataToSend: any = {
        message: {
          limit: 10,
          chatRoomId: store && store?.getState()?.chatRoomReducer?.activeChatRoom,
        },
      };
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt) {
        dataToSend.message.joinAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt;
      }
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt) {
        dataToSend.message.removeAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt;
      }

      dispatch(setClearChatMessages());
      getChatRoomsMessages(dataToSend);

      // else{
      //   dispatch(setNewMessages(data?.data));

      // }
    }
    if (data?.data?.roomData?.roomStatus === "Inactive") {
      const hasInactiveRoom =
        store &&
        store?.getState()?.chatRoomReducer?.professionalChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.professionalChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      const hasInactiveRoomArchive =
        store &&
        store?.getState()?.chatRoomReducer?.archiveChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.archiveChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      const hasInactiveSearch =
        store &&
        store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.searchChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomData?.chatRoomId) {
        if (hasInactiveRoom) {
          dispatch(
            setRoomDetails({
              ...hasInactiveRoom,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
        if (hasInactiveRoomArchive) {
          dispatch(
            setRoomDetails({
              ...hasInactiveRoomArchive,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
        if (hasInactiveSearch) {
          dispatch(
            setRoomDetails({
              ...hasInactiveSearch,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
      }
      if (hasInactiveRoom) {
        dispatch(removeProfessionalChatRoom(hasInactiveRoom));
      }

      if (hasInactiveRoomArchive) {
        dispatch(removeArchiveChatRoom(hasInactiveRoomArchive));
      }

      if (hasInactiveRoom) {
        dispatch(removeProfessionalChatRoom(hasInactiveRoom));
      }
      if (hasInactiveSearch) {
        dispatch(removeSearchChatRoom(hasInactiveSearch));
      }
      // dispatch(setInactiveChatRoomsList(data?.data));
    }
  };
  const handleCandidateWithdrawn = (data: any) => {
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.messageData?.message?.roomId) {
      const dataToSend: any = {
        message: {
          limit: 10,
          chatRoomId: store && store?.getState()?.chatRoomReducer?.activeChatRoom,
        },
      };
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt) {
        dataToSend.message.joinAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt;
      }
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt) {
        dataToSend.message.removeAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt;
      }
      dispatch(setClearChatMessages());
      getChatRoomsMessages(dataToSend);

      // else{
      //   dispatch(setNewMessages(data?.data));

      // }
    }
    if (data?.data?.roomData?.roomStatus === "Inactive") {
      const hasInactiveRoom =
        store &&
        store?.getState()?.chatRoomReducer?.professionalChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.professionalChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      const hasInactiveRoomArchive =
        store &&
        store?.getState()?.chatRoomReducer?.archiveChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.archiveChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      const hasInactiveSearch =
        store &&
        store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.searchChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomData?.chatRoomId) {
        if (hasInactiveRoom) {
          dispatch(
            setRoomDetails({
              ...hasInactiveRoom,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
        if (hasInactiveRoomArchive) {
          dispatch(
            setRoomDetails({
              ...hasInactiveRoomArchive,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
        if (hasInactiveSearch) {
          dispatch(
            setRoomDetails({
              ...hasInactiveSearch,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
      }

      if (hasInactiveRoom) {
        dispatch(removeProfessionalChatRoom(hasInactiveRoom));
      }
      if (hasInactiveRoomArchive) {
        dispatch(removeArchiveChatRoom(hasInactiveRoomArchive));
      }
      if (hasInactiveSearch) {
        dispatch(removeSearchChatRoom(hasInactiveSearch));
      }
      // dispatch(setInactiveChatRoomsList(data?.data));
    }
  };

  const handleJobClosed = (data: any) => {
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.messageData?.message?.roomId) {
      const dataToSend: any = {
        message: {
          limit: 10,
          chatRoomId: store && store?.getState()?.chatRoomReducer?.activeChatRoom,
        },
      };
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt) {
        dataToSend.message.joinAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt;
      }
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt) {
        dataToSend.message.removeAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt;
      }
      dispatch(setClearChatMessages());
      getChatRoomsMessages(dataToSend);

      // else{
      //   dispatch(setNewMessages(data?.data));

      // }
    }
    if (data?.data?.roomData?.roomStatus === "Inactive") {
      const hasInactiveRoom =
        store &&
        store?.getState()?.chatRoomReducer?.professionalChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.professionalChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      const hasInactiveRoomArchive =
        store &&
        store?.getState()?.chatRoomReducer?.archiveChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.archiveChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      const hasInactiveSearch =
        store &&
        store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.searchChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomData?.chatRoomId) {
        if (hasInactiveRoom) {
          dispatch(
            setRoomDetails({
              ...hasInactiveRoom,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
        if (hasInactiveRoomArchive) {
          dispatch(
            setRoomDetails({
              ...hasInactiveRoomArchive,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
        if (hasInactiveSearch) {
          dispatch(
            setRoomDetails({
              ...hasInactiveSearch,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
      }

      if (hasInactiveRoom) {
        dispatch(removeProfessionalChatRoom(hasInactiveRoom));
      }
      if (hasInactiveRoomArchive) {
        dispatch(removeArchiveChatRoom(hasInactiveRoomArchive));
      }
      if (hasInactiveSearch) {
        dispatch(removeSearchChatRoom(hasInactiveSearch));
      }
      // dispatch(setInactiveChatRoomsList(data?.data));
    }
  };
  const handleVideoUploadViaSession = (data: any) => {
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.messageData?.message?.roomId) {
      dispatch(setNewMessages({ message: data?.data?.messageData?.message }));
    }
  };
  const handlePersonalRestartChat = (data: any) => {
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.messageData?.roomId) {
      const dataToSend: any = {
        message: {
          limit: 10,
          chatRoomId: store && store?.getState()?.chatRoomReducer?.activeChatRoom,
        },
      };
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt) {
        dataToSend.message.joinAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt;
      }
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt) {
        dataToSend.message.removeAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt;
      }
      dispatch(setClearChatMessages());
      getChatRoomsMessages(dataToSend);

      // else{
      //   dispatch(setNewMessages(data?.data));

      // }
    }
    const dataToSendForUpdateRoom = {
      message: {
        chatRoomId: store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId,
      },
    };
    updateChatRoomEmit(dataToSendForUpdateRoom);
  };
  const handleRecruiterDecisionTaken = (data: any) => {
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.messageData?.roomId) {
      const dataToSend: any = {
        message: {
          limit: 10,
          chatRoomId: store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId,
        },
      };
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt) {
        dataToSend.message.joinAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.joinAt;
      }
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt) {
        dataToSend.message.removeAt = store && store?.getState()?.chatRoomReducer?.roomDetails?.metaData?.removeAt;
      }
      dispatch(setClearChatMessages());
      getChatRoomsMessages(dataToSend);

      // else{
      //   dispatch(setNewMessages(data?.data));

      // }
    }
    if (data?.data?.roomData?.roomStatus === "Inactive") {
      const hasInactiveRoom =
        store &&
        store?.getState()?.chatRoomReducer?.professionalChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.professionalChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.roomId
          );
      const hasInactiveRoomArchive =
        store &&
        store?.getState()?.chatRoomReducer?.archiveChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.archiveChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      const hasInactiveSearch =
        store &&
        store?.getState()?.chatRoomReducer?.searchChatRoomsList?.list?.length &&
        store &&
        store
          ?.getState()
          ?.chatRoomReducer?.searchChatRoomsList?.list?.find(
            (item: any) => item.roomId === data?.data?.messageData?.message?.roomId
          );
      if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomData?.chatRoomId) {
        if (hasInactiveRoom) {
          dispatch(
            setRoomDetails({
              ...hasInactiveRoom,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }

        if (hasInactiveRoomArchive) {
          dispatch(
            setRoomDetails({
              ...hasInactiveRoomArchive,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
        if (hasInactiveSearch) {
          dispatch(
            setRoomDetails({
              ...hasInactiveSearch,
              roomStatus: data?.data?.roomData?.roomStatus,
              // members: data?.data?.roomData?.members,
            })
          );
        }
      }

      if (hasInactiveRoom) {
        dispatch(removeProfessionalChatRoom(hasInactiveRoom));
      }
      if (hasInactiveRoomArchive) {
        dispatch(removeArchiveChatRoom(hasInactiveRoomArchive));
      }

      if (hasInactiveRoom) {
        dispatch(removeProfessionalChatRoom(hasInactiveRoom));
      }

      // dispatch(setInactiveChatRoomsList(data?.data));
    }
  };
  const handleUpdateInterviewDetails = (data: any) => {
    if (store && store?.getState()?.chatRoomReducer?.roomDetails?.roomId === data?.data?.roomData?.chatRoomId) {
      dispatch(setInterviewDetails({ ...data?.data?.interviewDetails }));
    }
  };

  return null;
};

export default ListneningEvents;

export interface WSListener {
  type: string;
  callBack: any;
}

let _url: string | URL;
let _deviceId: string;
let _userId: string;
let _dashboardId: string;
// let _reconnectInterval: number;
// let _maxReconnectAttempts: number;
let _ws: WebSocket | null;
let _listeners: WSListener[] = [];
let _isManualClose: boolean;
let _reconnectAttempts: number;

function connect(
  userId: string,
  deviceId: string,
  dashboardId: string,
  url: string | URL = process.env.NEXT_PUBLIC_SOCKET_DOMAIN ?? "",
  reconnectInterval = 1000,
  maxReconnectAttempts = 10
) {
  if (_ws && _dashboardId === dashboardId && _userId === userId) return; // Avoid multiple connections
  _dashboardId = dashboardId;
  _userId = userId;
  _deviceId = deviceId;
  // _listeners = [];
  _url = `${url}?userId=${userId}&deviceId=${deviceId}`;
  _ws = new WebSocket(_url);

  _ws.onopen = () => {
    console.log("WebSocket connected");
    // Notify all registered _listeners
    _listeners.forEach((listener) => {
      if (listener.type === "onstatuschange") {
        listener.callBack("WebSocket connected");
      }
      if (listener.type === "onopen") {
        listener.callBack();
      }
    });
    _reconnectAttempts = 0; // Reset reconnect attempts
  };

  _ws.onmessage = (event) => {
    // console.log('Message received:', event.data);
    // Notify all registered _listeners
    _listeners.forEach((listener) => {
      if (listener.type === "onmessage") {
        listener.callBack(event);
      }
    });
  };

  _ws.onerror = (error) => {
    console.error("WebSocket error:", error);
    // Notify all registered _listeners
    _listeners.forEach((listener) => {
      if (listener.type === "onstatuschange") {
        listener.callBack("WebSocket error");
      }
    });
  };

  _ws.onclose = () => {
    _listeners.forEach((listener) => {
      if (listener.type === "onstatuschange") {
        listener.callBack("WebSocket closed");
      }
    });
    // Notify all registered _listeners
    console.log("WebSocket closed");
    _ws = null;

    if (!_isManualClose && _reconnectAttempts < maxReconnectAttempts) {
      _reconnectAttempts++;
      const delay = reconnectInterval * _reconnectAttempts;
      console.log(`Reconnecting in ${delay}ms...`);
      _listeners.forEach((listener) => {
        if (listener.type === "onstatuschange") {
          listener.callBack(`WebSocket reconnecting in ${delay}ms...`);
        }
      });
      setTimeout(() => connect(_userId, _deviceId, _dashboardId), delay);
    } else if (_reconnectAttempts >= maxReconnectAttempts) {
      console.warn("Max reconnect attempts reached. Giving up.");
    }
  };
}

function send(data: any) {
  if (_ws && _ws.readyState === WebSocket.OPEN) {
    _ws.send(data);
  } else {
    console.warn("WebSocket is not open. Cannot send message.");
  }
}

function close() {
  _isManualClose = true;
  // _ws?.close();
  _ws = null;
}

function addWSEventListener(listener: WSListener) {
  _listeners.push(listener);
  return () => {
    // Remove listener on cleanup
    _listeners = _listeners.filter((l) => l !== listener);
  };
}

function isConnected() {
  return _ws?.readyState === WebSocket.OPEN;
}

export const WebSocketService = {
  connect,
  send,
  close,
  addWSEventListener,
  isConnected,
};

export const navHeadTitle = (url: string) => {
  if (typeof window !== "undefined") {
    let title = "";
    let redirectRoute = "";
    if (url?.includes("/feeds")) {
      title = "Feed";
    } else if (url?.includes("/jobs/my-jobs")) {
      title = "Jobs";
    } else if (url?.includes("/jobs/post")) {
      title = "Post a job";
    } else if (url?.includes("/jobs/review-applicants")) {
      title = "Jobs";
    } else if (url?.includes("/profile/edit")) {
      title = "Profile";
      redirectRoute = "/profile/view";
    } else if (url?.includes("/profile/view")) {
      title = "Profile";
    } else if (url?.includes("/registration/become-company")) {
      title = "Become Company";
    } else if (url?.includes("/registration/become-interviewer")) {
      title = "Become Interviewer";
    } else if (url?.includes("/settings/account-settings")) {
      title = "Settings";
    } else if (url?.includes("/jobs/list")) {
      title = "Open & Closed Roles";
    } else if (url?.includes("/jobs/apply")) {
      title = "Apply Job";
    } else if (url?.includes("/jobs/edit")) {
      title = "Edit Job";
    } else if (url?.includes("/invite-people")) {
      title = "Add People";
    } else if (url?.includes("/interviews/interviewer")) {
      title = "Interviews";
    } else if (url?.includes("/interviews/personal")) {
      title = "Interviews";
    } else if (url?.includes("/settings/administrative-controls")) {
      title = "Administrative Controls";
    } else if (url?.includes("/settings/payments")) {
      title = "Payments";
    } else if (url?.includes("/settings/company-invitations")) {
      title = "Invitations";
    } else if (url?.includes("/chat")) {
      title = "Chat";
    } else if (url?.includes("/feedback/interviewer")) {
      title = "Feedback";
    } else if (url?.includes("/feedback/recruiter")) {
      title = "Feedback";
    }
    return { title, redirectRoute };
  }
};

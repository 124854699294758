import { idTokenFromLocalStorage, idTokenFromSessionStorage } from "@/Utils/config";
import axiosMethods from "../https/https";
import catchAsync from "@/Utils/catchAsync";
//Getting lists of applied candidates
export const getAplliedApplicants = async (values: any) => {
  //eslint-disable-line
  const getAppliedListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/job-apply`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getAppliedListData;
};
//Getting lists of shortlisted candidates
export const getShortlistedApplicants = async (values: any) => {
  //eslint-disable-line
  const getShortListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/job-apply`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getShortListData;
};

//Getting lists of  candidates who are unselected
export const getUnselectedApplicants = async (values: any) => {
  //eslint-disable-line
  const getUnselectedListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/job-apply`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getUnselectedListData;
};

//Getting lists of added candidates amnually
export const getAddedApplicantsManually = async (values: any) => {
  //eslint-disable-line
  const getAddedApplicantsManuallyListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/job-apply`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getAddedApplicantsManuallyListData;
};

//Invite candidates manually
export const inviteCandiadtesManuallyApi = async (values: any) => {
  //eslint-disable-line
  const inviteCandiadtesManuallyData: any = await axiosMethods.post(
    //eslint-disable-line
    `/job-apply`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return inviteCandiadtesManuallyData;
};

//This is for multiple selection of candidate for either shorlist or Unselect or removed
export const shortListUnSelectRemovedDataRemovedApi = async (values: any) => {
  //eslint-disable-line
  const shortListUnSelectRemovedData: any = await axiosMethods.post(
    //eslint-disable-line
    `/job-apply`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return shortListUnSelectRemovedData;
};

//Getting lists of  candidates who are published
export const getPublishedApplicants = async (values: any) => {
  //eslint-disable-line
  const getPublishedListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getPublishedListData;
};

//This is for multiple selection of candidate for either republished or removed
export const republishedOrRemovedSelectionApi = async (values: any) => {
  //eslint-disable-line
  const republishedOrRemovedSelectionData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return republishedOrRemovedSelectionData;
};

// Walkout api for job seekers
export const walkoutApi = async (values: any) => {
  //eslint-disable-line
  const getWalkoutResponse = await axiosMethods.post(`/interview`, values.bodyData, {
    headers: {
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return getWalkoutResponse;
};

//Payemnt price details before publish
export const gePriceDetailsForPublishApi = async (values: any) => {
  //eslint-disable-line
  const gePriceDetailsForPublishApiData: any = await axiosMethods.post(
    //eslint-disable-line
    `/payment`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return gePriceDetailsForPublishApiData;
};

//For get payment intent
export const getPaymentIntentApi = async (values: any) => {
  //eslint-disable-line
  const getPaymentIntentApiData: any = await axiosMethods.post(
    //eslint-disable-line
    `/payment`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getPaymentIntentApiData;
};

/**
 * To save card for a specific user
 * @resource add-payment-source
 * @role Default || Comapany Owner
 * @body {
    // "companyId":"45", Need if payment is done from company
    "paymentMethodId":"pm_1PuWkYP0xqtTwgmXEaZkhe4E"
}
 */

export const saveCardForUser = catchAsync(async (values: any) => {
  //eslint-disable-line
  const getPaymentIntentApiData: any = await axiosMethods.post(
    //eslint-disable-line
    `/payment`,
    values.bodyData,
    {
      headers: {
        Resource: "add-payment-source",
        ProfileRole: values?.role,
      },
    }
  );
  return getPaymentIntentApiData;
});

/**
 * To save card for a specific user
 * @resource get-payment-sources
 * @role Default || Comapany Owner
 * @body {
    "stripeCustomerId":"cus_QktVGWlGABx4Zb"
}
 */

export const getSaveCardForUser: any = catchAsync(async (values: any) => {
  //eslint-disable-line
  const getPaymentIntentApiData: any = await axiosMethods.post(
    //eslint-disable-line
    `/payment`,
    values.bodyData,
    {
      headers: {
        Resource: "get-payment-sources",
        ProfileRole: values?.role,
      },
    }
  );
  return getPaymentIntentApiData.data;
});

/**
 * To delete card for a specific user
 * @resource remove-payment-source
 * @role Default || Comapany Owner
 * @body {
    "paymentMethodId":""
}
 */

export const deleteSaveCardForUser = catchAsync(async (values: any) => {
  //eslint-disable-line
  const deleteSavedCardApiData: any = await axiosMethods.post(
    //eslint-disable-line
    `/payment`,
    values.bodyData,
    {
      headers: {
        Resource: "remove-payment-source",
        ProfileRole: values?.role,
      },
    }
  );
  return deleteSavedCardApiData;
});

//Getting lists of selected candidates
export const getSelectedApplicants = async (values: any) => {
  //eslint-disable-line
  const getSelectedListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getSelectedListData;
};
export const getNotSelectedApplicants = async (values: any) => {
  //eslint-disable-line
  const getNotSelectedListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getNotSelectedListData;
};

"use client";

import React from "react";
import { persistedStore, store } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ComponnetType } from "@/Types/CommonComponents/UiComponentsType";
import LanguageProvider from "@/Components/Common/LanguageProvider";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import ConfigureAmplifyClientSide from "@/Utils/amplify-cognito-config";
import dynamic from "next/dynamic";
const ToastContainer = dynamic(() => import("react-toastify").then((mod) => mod.ToastContainer), { ssr: false });

// Dynamically import Bounce transition
const Bounce = dynamic(() => import("react-toastify").then((mod) => mod.Bounce), { ssr: false });

export function CustomProvider({ children }: ComponnetType) {
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        // hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        transition={Bounce}
      />
      <PersistGate persistor={persistedStore} loading={null}>
        <LanguageProvider>
          <ConfigureAmplifyClientSide />
          {children}
        </LanguageProvider>
      </PersistGate>
    </Provider>
  );
}

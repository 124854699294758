import React, { useEffect } from "react";
import TwoHalfLayoutUser from "./TwoHalfLayoutUser";
import { LayoutComponnetType } from "@/Types/CommonComponents/UiComponentsType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/Redux/store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { getUser } from "@/Redux/userProfile.slice";
import { RoleProfileType } from "@/Types/Profile/editProfileTypes";

const UserLayout = ({ children, headerText, route }: LayoutComponnetType) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>();
  const { selectedDashboardData, user } = useSelector((state: RootState) => state.userProfile);
  useEffect(() => {
    const handleReload = () => {
      const navigationEntries = window.performance.getEntriesByType("navigation");
      if (
        navigationEntries.length > 0 &&
        navigationEntries[0] instanceof PerformanceNavigationTiming &&
        navigationEntries[0].type === "reload"
      ) {
        // Page reloaded
        dispatch(
          getUser({
            resource: "get-user",
            profileRole: selectedDashboardData?.profileType,
            roleprofileid: user?.roleProfiles?.find(
              (roleProfile: RoleProfileType) => roleProfile?._id === selectedDashboardData?._id
            )?._id,
          })
        );
      }
    };
    if (selectedDashboardData?.profileType) {
      window.onload = handleReload;
    }
    return () => {
      window.onload = null; // Clean up event listener
    };
  }, [selectedDashboardData?.profileType]); // Empty dependency array ensures the effect runs only once
  // useEffect(() => {
  //   router.prefetch("/feeds");
  //   router.prefetch("/profile/view");
  //   router.prefetch("/jobs/post");
  //   router.prefetch("/jobs/list?value=1");
  //   router.prefetch("/interviews/personal?value=1");
  //   router.prefetch("/interviews/interviewer?value=1");
  //   router.prefetch("/chat");
  //   router.prefetch("/settings/administrative-controls");
  //   router.prefetch("/settings/company-invitations");
  //   router.prefetch("/settings/payments");
  //   router.prefetch("/settings/payouts");
  //   router.prefetch("/settings/change-password");
  // }, []);

  return (
    <TwoHalfLayoutUser headerText={headerText} route={route}>
      {children}
    </TwoHalfLayoutUser>
  );
};

export default UserLayout;
